<template>
  <div>
    <div class="pre-body pre-body-sm" v-if="!creating">
      <img class="pre-icon" src="/assets/images/profile.png" alt="" />

      <div class="pre-subhead centered">CREATE AN ACCOUNT</div>

      <div class="pre-copy centered">
        <p>Before you can join, you need to complete an evaluation.</p>
      </div>

      <div class="pre-divider"></div>

      <div class="pre-pad"></div>
      <b-form @submit.stop.prevent="createAccount">
        <error-alert v-if="anyError"> Missing required fields </error-alert>
        <error-alert v-if="createErr === 409">
          The email is already registered.
          <router-link :to="{ name: $routerItems.LOGIN }">Log in</router-link>
          or
          <router-link :to="{ name: $routerItems.FORGOT_PASSWORD }">
            Reset password</router-link
          >
        </error-alert>
        <error-alert v-if="createErr && createErr !== 409">
          Unexpected Error (Error Code: {{ createErr }})</error-alert
        >
        <div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b-form-group label="First Name" class="w-100">
                <pnw-input
                  id="first-name"
                  v-model="form.firstName"
                  placeholder="e.g. John"
                  :state="validateState('firstName')"
                  autocomplete="given-name"
                ></pnw-input>
              </b-form-group>
            </div>
            <div class="col-12 col-md-6">
              <b-form-group label="Last Name" class="w-100">
                <pnw-input
                  id="last-name"
                  v-model="form.lastName"
                  placeholder="e.g. Doe"
                  :state="validateState('lastName')"
                  autocomplete="family-name"
                ></pnw-input>
              </b-form-group>
            </div>
          </div>
          <label class="pre-form--copy"> DOB</label>
          <div class="row mb-3">
            <div class="col-12 col-md-4 mb-4">
              <pnw-select
                :class="{'vs__error' : anyError && !validateState('month')}"
                id="month"
                :options="months"
                v-model="form.month"
                placeholder="Month"
                :clearable="false"
                autocomplete="bday-month"
              />
            </div>
            <div class="col-12 col-md-4 mb-4">
              <pnw-select
                :class="{'vs__error' : anyError && !validateState('day')}"
                :options="days"
                v-model="form.day"
                placeholder="Day"
                :clearable="false"
                autocomplete="bday-days"
              />
            </div>
            <div class="col-12 col-md-4 mb-4">
              <pnw-select
                :class="{'vs__error' : anyError && !validateState('year')}"
                :options="years"
                v-model="form.year"
                placeholder="Year"
                :clearable="false"
                autocomplete="bday-year"
              />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 col-md-4 mb-4">
              <b-form-group label="Country">
                <v-select
                  :class="{'vs__error' : anyError && !validateState('country')}"
                  :options="countries"
                  label="country"
                  v-model="form.country"
                  placeholder="Select"
                  :clearable="false"
                />
              </b-form-group>
            </div>
            <div class="col-12 col-md-4 mb-4">
              <b-form-group label="State/Province">
                <v-select
                  :options="states"
                  v-model="form.state"
                  placeholder="Select"
                  :clearable="false"
                  :disabled="!states.length"
                />
              </b-form-group>
            </div>
            <div class="col-12 col-md-4 mb-4">
              <b-form-group label="Postal Code" class="w-100">
                <pnw-input
                  id="postal-code"
                  v-model="form.postalCode"
                  placeholder="e.g. 123456"
                  :state="validateState('postalCode')"
                  autocomplete="postal-code"
                ></pnw-input>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="pre-pad my-5"></div>

        <div class="pre-divider"></div>

        <div class="pre-pad my-5"></div>
        <div v-if="!$route.query.email || !$route.query.password">
          <b-form-group label="Email" class="email-field">
            <template slot="label">
              <div class="d-flex justify-content-between">
                <label>Email</label>
                <b-form-text
                  text-variant="danger"
                  v-if="isValidEmail === false"
                >
                  Invalid email
                </b-form-text>
                <b-form-text text-variant="danger" v-if="duplicated">
                  The email exists in our system. Please login.
                </b-form-text>
              </div>
            </template>
            <pnw-input
              v-model="form.email"
              :state="isValidEmail && !duplicated"
              :class="{'is-invalid' : anyError && !validateState('email')}"
              type="email"
              placeholder="name@email.com"
              @keyup="emailValidation"
              autocomplete="username"
            ></pnw-input>
            <b-spinner
              variant="primary"
              label="Spinning"
              v-if="emailChecking"
              class="spinner"
            ></b-spinner>
          </b-form-group>
          <b-form-group label="Password">
            <template slot="label">
              <div class="d-flex justify-content-between">
                <label>Password</label>
                <b-form-text
                  :text-variant="
                    isValidPassword.valid === false ? 'danger' : 'helper'
                  "
                >
                  Minimum of 8 alphanumeric characters.
                </b-form-text>
              </div>
            </template>
            <pnw-input
              v-model="form.password"
              :state="isValidPassword.valid"
              :class="{'is-invalid' : anyError && !validateState('password')}"
              type="password"
              placeholder="Enter password"
              autocomplete="new-password"
            ></pnw-input>
          </b-form-group>
          <b-form-group label="Confirm Password">
            <template slot="label">
              <div class="d-flex justify-content-between">
                <label>Confirm Password</label>
                <b-form-text text-variant="danger" v-if="isMatched === false">
                  Password does not match
                </b-form-text>
              </div>
            </template>
            <pnw-input
              v-model="form.confirmPassword"
              type="password"
              :state="isMatched"
              :class="{'is-invalid' : anyError && !validateState('confirmPassword')}"
              placeholder="Confirm Password"
              autocomplete="new-password"
            ></pnw-input>
          </b-form-group>

          <div class="pre-pad my-5"></div>

          <div class="pre-divider"></div>

          <div class="pre-pad my-5"></div>
        </div>
        <b-form-checkbox class="mb-2" v-model="form.isAdult" disabled
          >I am over 18 years</b-form-checkbox
        >
        <b-form-checkbox class="mb-2" v-model="form.previouslyAttended"
          >I have previously attended Secret Cinema</b-form-checkbox
        >
        <b-form-checkbox class="mb-2" v-model="form.mailingList"
          >Hear more from Secret Cinema, including: show announcements,
          promotions and exclusive events</b-form-checkbox
        >

        <b-form-checkbox class="mb-2" v-model="form.sonyMailingList"
          >I would like to receive email updates from Sony Pictures
          Entertainment (“SPE”)</b-form-checkbox
        >
        <div class="pre-terms pl-4">
          <p>
            I will also get the Sony Pictures Spotlight newsletter, my guide to
            the latest in upcoming movies, soundtracks, free downloads, great TV
            shows, DVD releases, cool promotions & events and more. By clicking
            SUBMIT, I certify that I am a United States resident, I acknowledge
            that my information will be handled and used by SPE in accordance
            with the SPE Privacy Policy, and I agree to such handling and use by
            SPE.
          </p>
        </div>
        <div class="pre-pad my-5"></div>
        <div class="pre-terms">
          <p>
            Registering on this website will create a Secret Cinema account that
            can be used across different websites in the future,
            <a href="#">learn more here</a>. To understand how Secret Group will
            use your personal information please read our
            <a href="#">privacy statement</a>.
          </p>
        </div>

        <div class="pre-pad my-5"></div>
        <div class="d-flex justify-content-center">
          <pnw-button
            type="submit"
            variant="primary"
            size="xl"
            @click="createAccount"
            :disabled="creating"
          >
            CREATE ACCOUNT
          </pnw-button>
        </div>
      </b-form>
      <adult-check-modal :isAdult="form.isAdult" />
    </div>
    <connecting v-if="creating" />
  </div>
</template>
<script>
import http from "../../services/HttpService";
import { validationMixin } from "vuelidate";
import validations from "../../utils/validations";
import { mapActions } from "vuex";
import MONTHS, { MONTH_NUMBERS } from "../../constants/dateConstants";
import dayHelpers from "../../utils/dayHelper";
import routerItems from "../../constants/routerItems";
import AdultCheckModal from "../../components/AdultCheckModal.vue";
import ErrorAlert from "../../components/ErrorAlert.vue";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import Connecting from "../onboarding/Connecting.vue";
import PnwButton from "../../components/PnwButton.vue";
import PnwSelect from "../../components/PnwSelect.vue";
import PnwInput from "../../components/PnwInput.vue";
import auth from "../../services/AuthService";
import playSound from "../../services/AudioService";
import countries from "../../constants/countries";

export default {
  name: "Register",
  mixins: [validationMixin],
  components: {
    AdultCheckModal,
    ErrorAlert,
    Connecting,
    PnwButton,
    PnwSelect,
    PnwInput,
  },
  data() {
    return {
      debounceTimer: null,
      form: {
        email: "",
        password: "",
        confirmPassword: "",

        firstName: "",
        lastName: "",

        month: "",
        day: "",
        year: "",

        postalCode: "",
        state: null,
        country: null,

        isAdult: false,
        previouslyAttended: false,
        mailingList: false,
        sonyMailingList: false,
      },
      years: [],
      days: [],
      countries: countries,
      states: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      emailChecking: false,
      creating: false,
      duplicated: false,
      isValidEmail: false,
      isValidPassword: { valid: null },
      anyError: false,
      createErr: null,
    };
  },
  validations: {
    form: {
      email: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        sameAsPassword: sameAs("password"),
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      month: {
        required,
      },
      day: {
        required,
      },
      year: {
        required,
      },
      country: {
        required,
      },
      postalCode: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      updateOnboardingData: "registerStore/updateOnboardingData",
      setHideMenuAndFooter: "setHideMenuAndFooter",
    }),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async confirmAdult() {
      this.anyError = false;
      this.creating = true;
      this.createErr = null;
      this.setHideMenuAndFooter(this.creating);
      try {
        const userInfo = {
          updateInfo: this.$route.query.email && this.$route.query.password,
          email: this.form.email,
          password: this.form.password,
          confirmPassword: this.form.confirmPassword,

          firstName: this.form.firstName,
          lastName: this.form.lastName,
          postalCode: this.form.postalCode,
          state: this.form.state,
          country: this.form.country?.country,

          isAdult: this.form.isAdult,
          previouslyAttended: this.form.previouslyAttended,
          mailingList: this.form.mailingList,
          sonyMailingList: this.form.sonyMailingList,

          dob: new Date(
            parseInt(this.form.year),
            MONTH_NUMBERS[this.form.month],
            parseInt(this.form.day)
          ),
        };
        await http.post("/api/pnw/register", userInfo);
        await auth.login({
          email: this.form.email,
          password: this.form.password,
        });

        this.updateOnboardingData({ isAdult: true });
        this.$router.push({
          name: routerItems.TICKET_CODE,
        });
      } catch (err) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        this.createErr = err?.response?.status || 'unknown error';
      }
      this.creating = false;
      this.setHideMenuAndFooter(this.creating);
    },
    async emailValidation() {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(async () => {
        if (this.isValidEmail) {
          this.emailChecking = true;
          try {
            const { data } = await http.post("api/pnw/email-check", {
              email: this.form.email,
            });
            if (data.valid) {
              this.duplicated = false;
            } else {
              this.duplicated = true;
            }
          } catch (err) {
            console.log(err);
          }
          this.emailChecking = false;
        }
      });
    },
    async createAccount() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.anyError = true;
        playSound("GF-SpiritNet-Error.wav");
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        return;
      }
      if (!this.form.isAdult) {
        this.$bvModal.show("adult-check-modal");
      } else {
        this.confirmAdult(true);
      }
    },
    generateDays() {
      this.days = dayHelpers.generateMonthDays(
        this.form.month || MONTHS.January,
        this.form.year
      );
    },
    updateAdultCheckbox() {
      const now = new Date();
      if (now.getFullYear() - this.form.year < 18) {
        this.form.isAdult = false;
      } else {
        this.form.isAdult = true;
      }
    },
  },
  mounted() {
    const thisYear = 2005;
    this.years = [];
    for (let year = thisYear; year >= thisYear - 100; year--) {
      this.years.push(year);
    }
    this.generateDays();
    this.form.email = this.$route.query.email;
    this.form.password = this.$route.query.password;
    this.form.confirmPassword = this.$route.query.password;
  },
  watch: {
    "form.country": function (country) {
      this.states = country.states;
      this.form.state = "";
    },
    "form.month": function () {
      this.generateDays();
    },
    "form.year": function () {
      this.generateDays();
      this.updateAdultCheckbox();
    },
    "form.email": function (newValue) {
      this.duplicated = false;
      this.isValidEmail = validations.isValidEmail(newValue);
    },
    "form.password": function (newValue) {
      this.isValidPassword = validations.isValidPassword(newValue);
    },
  },
  computed: {
    canContiue() {
      if (
        !this.form.month ||
        !this.form.day ||
        !this.form.year ||
        !this.form.gender ||
        !this.form.state ||
        !this.form.country
      ) {
        return false;
      }
      return true;
    },
    isMatched() {
      if (!this.form.confirmPassword) return null;
      return this.form.password === this.form.confirmPassword;
    },
  },
};
</script>
<style lang="scss" scoped>
.auth-form {
  max-width: 600px;
  &.wide-auth-form {
    max-width: 800px;
  }
}
.email-field {
  position: relative;
  .spinner {
    position: absolute;
    right: 7px;
    top: 7px;
    z-index: 100;
  }
}
</style>
