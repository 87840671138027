function isValidPassword(password = "") {
  const hasMinimumLength = password.length >= 8;
  return {
    hasMinimumLength,
    valid: password ? hasMinimumLength : null,
  };
}

function isValidEmail(email) {
  /* eslint-disable */
  return email
    ? /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    : null;
}

const validations = {
  isValidPassword,
  isValidEmail,
};

export default validations;
