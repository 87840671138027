<template>
  <modal-layout :id="id">
    <div class="content-body">
      <div class="title">
        Profiles for children under 18 years requires an adult.
      </div>
      <p class="mb-5">
        Have an adult purchase a child ticket and create an account to add a
        child’s profile.
      </p>
      <div class="d-flex justify-content-end">
        <pnw-button
          size="xl"
          variant="primary"
          @click="closeAdultCheckModal()"
          >{{ isAdult ? "CONFIRM" : "OK" }}</pnw-button
        >
      </div>
    </div>
  </modal-layout>
</template>
<script>
import ModalLayout from "./ModalLayout.vue";
import PnwButton from "./PnwButton.vue";
export default {
  components: { ModalLayout, PnwButton },
  name: "AdultCheckModal",
  props: {
    isAdult: {
      require: true,
      default: true,
    },
  },
  data() {
    return {
      id: "adult-check-modal",
    };
  },
  methods: {
    closeAdultCheckModal() {
      this.$bvModal.hide(this.id);
      this.$emit("confirmAdult", true);
    },
    close() {
      this.$bvModal.hide(this.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.content-body {
  padding: 16px 48px;
  .title {
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;
    margin-bottom: 32px;
  }
}
</style>
