<template>
  <div class="pre-body pre-body-sm">
    <div class="pre-conecting">
      <img src="/assets/images/knightrider.png" alt="" />
      <img src="/assets/images/analyze.png" alt="" />
      <img class="flipped" src="/assets/images/knightrider.png" alt="" />
    </div>

    <div class="pre-subhead centered caps">Connecting to Spirit Net...</div>
  </div>
</template>
<script>
export default {
  name: "Connecting",
};
</script>
